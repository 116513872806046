$toggle_screen_width_medium: 900px;
$toggle_screen_width_mediumish: 800px;
$toggle_screen_width_smallerish: 650px;
$toggle_screen_width_smaller: 500px;
$large_width: 800;
$large_width_px: 800px;
$large_width_mobile: 500;
$large_width_mobile_px: 500px;
$large_width_mobile_small: 375;
$large_width_mobile_small_px: 375px;
$blog_img_width_small: 365;
$blog_img_width_small_px: 365px;
$medium_width: 400;
$medium_width_px: 400px;
$border_width: 1;
