.admin-title
{
	font-family: mainFont;
	margin-bottom: 0em;
	padding-bottom: 0em;
	text-transform: uppercase;
	font-size: 30px;
	margin-left: 50%;
	margin-right: 50%;
	white-space: nowrap;
}

.admin-page-container
{
	display: flex;
	flex-direction: row;
}

.html-element-container
{
	display: flex;
	flex-direction: column;
}

.row-input
{
	width: 800px
}

.upload-size
{
	width: 80px;
}

.edit-post-submit-button
{
	position: fixed;
	right: 200px;
	bottom: 25px;
}

.photo-element-toolbar-title
{
	background-color: black;
	color: white;
	text-align: center;
}

.photo-element-toolbar-container
{
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.photo-element-toolbar-container span
{
	cursor: pointer;
}

.photo-element-container
{
	display: table;
	width: 100%;
}

.photo-element-container-row
{
	display: table-row;
}

.photo-element-container-row > div
{
	display: table-cell;
}

.photo-element-container span, .photo-element-delete
{
	cursor: pointer;
}

.photo-option
{
	display: flex;
	flex-direction: row;
}
