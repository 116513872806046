html, body, #root, #root>div
{
    height: 100%;
    margin: 0;
    padding: 0;
}
.centerMe
{
	margin-left: auto;
	margin-right: auto;
	width: 500px;
}
body
{
	/*background-color: #292929;*/
	background-color: white;
}
td
{
	text-align: center;
	padding-bottom: 8px;
}
.table-left tr td
{
	text-align: left;
}
.alignTop
{
	vertical-align: top;
}
.mainBody
{
	text-align: center;
}

@media (min-width: $toggle_screen_width_medium)
{
	.mainBody
	{
		width: $large_width_px;
	}
}
@media (max-width: $toggle_screen_width_medium)
{
	.mainBody
	{
		width: $large_width_px;
	}
}
@media (max-width: $toggle_screen_width_smaller)
{
	.mainBody
	{
		width: $large_width_mobile_small_px;
	}
}

article, button
{
		font-family: mainFont;
		color: black;
		line-height: 24px;
}
article.about-container
{
	line-height: unset;
}
.articleTitle {
	text-transform: uppercase;
	font-size: 30px;
	line-height: 1.5
}

@media (min-width: $toggle_screen_width_medium)
{
	article
	{
		width: unset;
	}
}
@media (max-width: $toggle_screen_width_medium)
{
	article
	{
		width: unset;
	}
}
@media (max-width: $toggle_screen_width_smaller)
{
	article
	{
		width: unset;
	}
}

.nowrap
{
	white-space: nowrap;
}
.floatLeft
{
		float: left;
}
.floatRight
{
		float: right;
}
.alignLeft
{
		text-align: left;
}
.alignCenter, .align-center
{
		text-align: center;
}

.footer
{
	font-family: mainFont;
	font-size: 12px;
	text-transform: uppercase;
}

.width100
{
	width: 100%;
}

.alignElementCenter, .align-element-center {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.error
{
	color: red !important;
}

.green-message
{
	color: rgb(0, 153, 0) !important;
}

.upper-case
{
	text-transform: uppercase;
}
