/*****
Overrides
******/
.navbar
{
	padding-right: 0px;
}

a
{
	font-family: mainFont;
	text-transform: uppercase;
	color: #292929;
//	font-size: 12pt;
}

a:hover
{
	text-decoration: none;
	color: #292929;
	text-shadow: 0px 0px 10px #292929;
}
a.reg, a.blog
{
	text-transform: none;
}
a.blog-upper
{
	text-transform: uppercase;
}
a.reg, a.blog, a.blog-upper
{
	font-weight: normal;
	font-family: mainFont;
	text-decoration: underline;
	color: #292929;
}
a.reg
{
	font-size: 14px;
}
a.reg:hover
{
	text-decoration: none;
	text-shadow: none;
}
a.blog:hover, a.blog-upper:hover
{
	text-decoration: none;
}
a.blue-link
{
	text-transform: none;
	color: blue !important;
}
.hamburger
{
	display: none;
}

@media (max-width: $toggle_screen_width_medium) {
	.hamburger
	{
		display: block;
		position: absolute;
	}
	.links
	{
		display: none;
		position: absolute;
	}
	.hamburgerlinks
	{
		display: block;
		background-color: white;
		position: absolute;
		z-index: 10;
	}
	.hamburgerMenu
	{
		cursor: pointer;
	}
	.closeHamburgerNavigation
	{
		display: block;
		position: absolute;
		right: 0px;
		color: red;
		cursor: pointer;
	}
}
@media (min-width: $toggle_screen_width_medium) {
	.closeHamburgerNavigation
	{
		display: none;
	}
}
.links, .hamburgerlinks
{
	text-align: left;
	line-height: 200%;
	width: 170px;
}
.links div, .hamburgerlinks div
{
	padding-bottom: 30px;
}

.links .sublinks, .links .subnonlinks, .links div.categoryHead, .hamburgerlinks .sublinks, .hamburgerlinks .subnonlinks, .hamburgerlinks div.categoryHead
{
	padding-bottom: 5px;
}
.links .sublinks, .links .subnonlinks, .hamburgerlinks .sublinks, .hamburgerlinks .subnonlinks
{
	padding-left: 12px;
}
.links .sublinks a, .links .subnonlinks a, .hamburgerlinks .sublinks a, .hamburgerlinks .subnonlinks a
{
	font-size: 10pt;
}
.links .subsublinks, .links .subsubnonlinks, .hamburgerlinks .subsublinks, .hamburgerlinks .subsubnonlinks
{
	font-size: 10pt;
	padding-left: 24px;
	padding-bottom: 5px;
}

div.mainCategoryCurrent
{
		font-weight: bold;
}
