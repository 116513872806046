@media (min-width: $toggle_screen_width_medium) {
	.photo_horizontal_medium
	{
		width: 800px !important;
		height: 534px !important;
		padding-bottom: 0px !important;
		margin-left: 6px !important;
		margin-right: 6px !important;
	}
	.photo_vertical_medium
	{
		width: 356px !important;
		height: 534px !important;
		margin-left: 228px !important;
		margin-right: 228px !important;
		padding-bottom: 0px !important;
	}
}
// Width: unset fix is most important so image looks normal vertically
.photo_vertical_medium img
{
	width: unset;
	margin-left: auto;
	margin-right: auto;
}


.photo_horizontal_medium_irregheight img
{
	height: unset;
}

.photo_horizontal_small
{
	width: 62px !important;
	height: 41px !important;
}

.photo_vertical_small
{
	width: 41px !important;
	height: 27px !important;
}

.photo_thumbnail
{
	margin-right: 2px;
	margin-top: 2px;
	padding: 0;
	border: 1px solid black;
	background: none;
}
.photoLargeSlider
{
	margin-bottom: 5px;
}
.noButton
{
	padding: 0;
	border: none;
	background: none;
}

.photoNavigation, .photoThumbnailContainer, .photoGalleryDescriptionContainer
{
	text-align: center;
	margin: auto;
}

@media (min-width: $toggle_screen_width_medium) {
	.photoNavigation, .photoThumbnailContainer, .photoGalleryDescriptionContainer
	{
		width: $large_width_px;
	}
}
@media (max-width: $toggle_screen_width_medium) {
	.photoNavigation, .photoThumbnailContainer, .photoGalleryDescriptionContainer
	{
		width: $large_width_mobile_px;
	}
}
@media (max-width: $toggle_screen_width_smaller)
{
	.photoNavigation, .photoThumbnailContainer, .photoGalleryDescriptionContainer
	{
		width: $large_width_mobile_small_px;
	}
}
.navigationContainer
{
	height: 41px;
	vertical-align: middle;
}
.photoGalleryDescriptionContainer
{
	margin-bottom: 10px;
}
