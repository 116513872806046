@font-face {
	font-family: mainFont;
	src: url('../fonts/SF Grandezza Light.ttf');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: mainFont;
	src: url('../fonts/SF Grandezza Light Oblique.ttf');
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: mainFont;
	src: url('../fonts/SF Grandezza Medium.ttf');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: mainFont;
	src: url('../fonts/SF Grandezza Medium Oblique.ttf');
	font-weight: bold;
	font-style: italic;
}
@font-face {
	font-family: mainFont;
	src: url('../fonts/SF Grandezza Heavy.ttf');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: mainFont;
	src: url('../fonts/SF Grandezza Heavy Oblique.ttf');
	font-weight: 900;
	font-style: italic;
}
