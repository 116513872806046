.photo-category
{
	width: 342px;
	border: 1px solid white;
	vertical-align: top;
	font-family: mainFont;
	text-transform: uppercase;
	color: #292929;
	font-size: 12pt;
}
.photo-category:hover
{
	border: 1px solid #292929;
	cursor: pointer;
	cursor: hand;
}
.photoBookTable
{
	border-collapse: separate;
	margin-left: auto;
	margin-right: auto;
}
.photoTitle, .photoSubTitle, .photoDate, .photo-author
{
	font-family: mainFont;
	margin-bottom: 0em;
	padding-bottom: 0em;
}
.photoTitle
{
	text-transform: uppercase;
	font-size: 30px;
}
.photoSubTitle
{
	font-size: 20px;
}
.optionsBar
{
	display: flex;
	justify-content: center;
}
@media (max-width: $toggle_screen_width_smaller) {
	.optionsBar
	{
		flex-direction: column;
	}
}
@media (min-width: $toggle_screen_width_smaller) {
	.optionsBar
	{
		flex-direction: row;
	}
}
.photoDate
{
	font-size: 15px;
}
.photo-author
{
	font-size: 15px;
	font-style: italic;
	margin-bottom: 10px;
}

.regText, .regTextBold, .regTextItalic
{
	font-family: mainFont;
	font-weight: normal;
	color: #292929;
	font-size: 15px;
	line-height: 170%;
}
.regTextBold
{
	font-weight: bold;
}
.vertical-align-top
{
	vertical-align: top;
}
.regTextItalic
{
	font-style: italic;
}
.two-col-description
{
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.two-col-description div
{
	padding: 10px;
}
