@media (min-width: $toggle_screen_width_medium) {
	.homeheader
	{
		font-size: 35px;
		padding-top: 20px;
		padding-bottom: 35px;
	}
	.photoRow
	{
		flex-direction: row;
	}
}
@media (max-width: $toggle_screen_width_medium) {
	.homeheader
	{
		font-size: 30px;
		padding-top: 20px;
		padding-bottom: 20px;
	}
	.photoRow
	{
		flex-direction: column;
	}
}
@media (max-width: $toggle_screen_width_smaller) {
	.homeheader
	{
		font-size: 24px;
		padding-top: 16px;
		padding-bottom: 16px;
	}
	.photoRow
	{
		flex-direction: column;
	}
}
.homeheader
{
	text-transform: uppercase;
	color: #292929;
	text-align: center;
	font-family: mainFont;
}
.homePageBox
{
	display: flex;
	flex-direction: column;
	align-items: center;
}
.photoRow
{
	display: flex;
}
.homePageContainer
{
	height: 100%;
	text-align: center;
}
.homeTable
{
	border-collapse: separate;
}
@media (min-width: $toggle_screen_width_medium)
{
	.homePageFooter
	{
		margin-top: 60px;
	}
}
@media (max-width: $toggle_screen_width_medium)
{
	.homePageFooter
	{
		margin-top: 25px;
	}
}

.homePageFooter
{
	padding-bottom: 11px;
	margin-left:auto;
	margin-right:auto;
}
