@import '_variables.scss';
$two_photos_margin_right: 5px;

.horizontalFigure
{
		padding: 0px;
		border: 1px solid black;
		display: flex;
		align-items: flex-start;
}
@media (min-width: $toggle_screen_width_smallerish)
{
	.horizontalFigure
	{
		flex-direction: row;
	}
}
@media (max-width: $toggle_screen_width_smallerish)
{
	.horizontalFigure
	{
		flex-direction: column;
	}
}

.horizontalFigure figure
{
		border-width: 0px !important;
		margin: 0px !important;
}
.horizontalFigure div
{
	padding-top: 10px;
	padding-right: 10px;
}
.horizontalAlign, .horizontal-align
{
		display: flex;
		align-items: flex-start;
}
@media (min-width: $toggle_screen_width_medium) {
	.horizontalAlign, .horizontal-align
	{
		flex-direction: row;
	}
}
@media (max-width: $toggle_screen_width_medium) {
	.horizontalAlign, .horizontal-align
	{
		flex-direction: column;
	}
}
@media (max-width: $toggle_screen_width_smaller)
{
	.horizontalAlign, .horizontal-align
	{
		flex-direction: column;
	}
}

.horizontalAlignCenter
{
	justify-content: center;
}

.verticalAlign, .photo-rectangle-collection, .vertical-align-center
{
		display: flex;
		flex-direction: column;
}
.vertical-align-center
{
	align-items: center;
}

.photo-rectangle-collection
{
	border: 1px solid black;
	width: $large_width_px  + ($border_width * 2) + 0px;
}
.photo-rectangle-collection-text
{
	padding: 13px;
  width: 100%;
}

figcaption
{
		font-family: mainFont;
}

/* Blog post */
.blogpost h2, .blogpost h4, .blogpost h5, .blogpost h6, h2.categorySubtitle, h3.categorySubtitle
{
		font-family: mainFont;
		color: black;
}

.blogpost article
{
		font-family: mainFont;
		color: black;
		text-align: left;
		line-height: 24px;
}

.blogpost figure
{
		border: 1px solid black;
		margin: 2px;
}

@media (min-width: $toggle_screen_width_smaller)
{
	.blogpost figure
	{
		width: $medium_width + ($border_width * 2) + 0px;
	}
}
@media (max-width: $toggle_screen_width_smaller)
{
	.blogpost figure
	{
		width: $blog_img_width_small + ($border_width * 2) + 0px;
	}
}

.blogpost figure.small, .blogpost figure.vertical
{
		width: 267px;
		border: 1px solid black;
		margin: 2px;
}

.blogpost figure.verticalSmall
{
		width: 229px;
		border: 1px solid black;
		margin: 2px;
}

@media (min-width: $toggle_screen_width_smaller)
{
	.blogpost figure img
	{
		width: $medium_width_px;
	}
}
@media (max-width: $toggle_screen_width_smaller)
{
	.blogpost figure img
	{
		width: $blog_img_width_small_px;
	}
}

.blogpost figure.natural-width
{
	width: unset;
	border-width: 0px;
}

.blogpost figure.natural-width img
{
	width: unset;
}

.blogpost figure.small img, .blogpost figure.vertical img
{
		width: 265px;
}

.blogpost figure.verticalSmall img
{
		width: 227px;
}

.blogpost figure img
{
		display: block;
}

.blogpost figure.two-photos img
{
		display: inline;

}

.blogpost figure.two-photos img:first-child
{
		margin-right: $two_photos_margin_right;
		border-bottom: 1px solid black;
		border-right: 1px solid black;
}

.blogpost figure.two-photos img:last-child
{
		border-bottom: 1px solid black;
		border-left: 1px solid black;
}
@media (min-width: $toggle_screen_width_mediumish)
{
	.blogpost figure.large
	{
			width: $large_width + ($border_width * 2) + 0px;
			border: 1px solid black;
			margin: 2px;
	}
	.blogpost figure.two-photos
	{
			width: $large_width + ($border_width * 2) + $two_photos_margin_right;
			border: 1px solid black;
			margin: 2px;
	}
	.blogpost figure.large img
	{
		width: $large_width_px;
	}
}
@media (max-width: $toggle_screen_width_mediumish)
{
	.blogpost figure.large
	{
			width: $large_width_mobile + ($border_width * 2) + 0px;
			border: 1px solid black;
			margin: 2px;
	}
	.blogpost figure.large img
	{
		width: $large_width_mobile_px;
	}
	.photo-rectangle-collection
	{
		width: $medium_width_px + ($border_width * 2) + 0px;
	}
}
@media (max-width: $toggle_screen_width_smaller)
{
	.blogpost figure.large
	{
			width: $blog_img_width_small + ($border_width * 2) + 0px;
			border: 1px solid black;
			margin: 2px;
	}
	.blogpost figure.large img, .photo-rectangle-collection
	{
		width: $blog_img_width_small_px;
	}
}

.blogpost figcaption
{
		padding: 1px;
		text-align: center;
		text-transform: uppercase;
		font-size: 13px;
}
.subheader
{
	font-family: mainFont;
	font-weight: normal;
	color: #292929;
	font-size: 20px !important;
	text-transform: uppercase !important;
}
.filmSubheader
{
		width: 400px !important;
		text-align: center;
}
.leftMargin10, .left-margin-10
{
		margin-left: 10px;
}
.right-margin-10
{
		margin-right: 10px;
}

.leftPadding25
{
		padding-left: 25px;
}
.rightPadding25
{
		padding-right: 25px;
}

.canExpand, .can-expand
{
	cursor: pointer;
	transition: 0.3s;
}

.maineflexcolumn
{
	display: flex;
	flex-direction: column;
}

.boldnote
{
	text-align: center;
	font-weight: bold;
}

@media (max-width: $toggle_screen_width_smaller)
{
	.flexImage, .flex-image
	{
		width: $large_width_mobile_small_px;
	}
}

@media (min-width: $toggle_screen_width_medium)
{
	.only-small
	{
		display: none;
	}
}
@media (max-width: $toggle_screen_width_medium)
{
	.only-large
	{
		display: none;
	}
}
